import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Col, Button, message, Row, DatePicker, Select } from "antd";
import { TextField } from "@mui/material";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const { Option } = Select;

function ListGroups(props) {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [workshopAddress, setWorkshopAddress] = useState("");
  const [PDFNotes, setPDFNotes] = useState("");
  const [paymentConditions, setPaymentConditions] = useState("");
  const [tAndC, setTAndC] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [deliveryExecutive, setDeliveryExecutive] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [spinnerState, setSpinnerState] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [bankBlnc, setBankBlnc] = useState({});
  const [bankName, setBankName] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemById(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ManageShopDetails")) navigate("/homePage");
  }, []);

  const getItemById = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setAddress(res.address);
      setWorkshopAddress(res.workshopAddress ?? "");
      setPDFNotes(res.PDFNotes ?? "");
      setPaymentConditions(res.paymentConditions ?? "");
      setTAndC(res.tAndC ?? "");
      setGSTNumber(res.GSTNumber ?? "");
      setEmail(res.email ?? "");
      setBankDetails(res.bankDetails ?? "");
      setPhoneNumber(res.phoneNumber ?? "");
      setDeliveryExecutive(res.deliveryExecutive ?? "");
      setHolidayList(res.holidayList ?? []);
      setBankBlnc(res.bankBlnc ? JSON.parse(res.bankBlnc) : {});
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: outletShopId,
          address: address,
          workshopAddress: workshopAddress,
          PDFNotes: PDFNotes,
          paymentConditions: paymentConditions,
          tAndC: tAndC,
          GSTNumber: GSTNumber,
          email: email,
          bankDetails: bankDetails,
          phoneNumber: phoneNumber,
          deliveryExecutive: deliveryExecutive,
          holidayList: holidayList,
          bankBlnc: JSON.stringify(bankBlnc),
        },
      });
      message.error("Shop Details Updated");
      navigate("/HomePage");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    setSpinnerState("true");
    editItem();
  };

  const addToList = (holiday) => {
    let temp = [...holidayList];
    temp.push(holiday.format("YYYY-MM-DD"));
    setHolidayList(temp);
    setDate(dayjs(new Date()));
  };

  const removeFromList = (holiday) => {
    let temp = [...holidayList];
    const index = temp.indexOf(holiday);
    if (index > -1) temp.splice(index, 1);
    setHolidayList(temp);
  };

  const addBalance = () => {
    let temp = { ...bankBlnc };
    temp[bankName] = {};
    temp[bankName].id = uuidv4();
    temp[bankName].bankName = bankName;
    temp[bankName].openingBalance = parseFloat(openingBalance);
    temp[bankName].currentBalance = parseFloat(openingBalance);
    temp[bankName].date = moment(new Date()).format("YYYY-MM-DD");
    console.log(JSON.stringify(temp));
    setBankBlnc(temp);
    setBankName("");
    setOpeningBalance(0);
  };

  const removeBalance = (key) => {
    let temp = { ...bankBlnc };
    delete temp[key];
    setBankBlnc(temp);
  };

  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Col
          xs={24}
          sm={3}
          offset={16}
          style={{ marginTop: 10, marginBottom: 40 }}
        >
          <Button
            onClick={() => checkInput()}
            type="primary"
            disabled={spinnerState}
          >
            Update Shop Details
          </Button>
        </Col>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Phone Number"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={phoneNumber}
              size="small"
              onChange={(e) => setPhoneNumber(e.target.value)}
              inputProps={{
                maxLength: 15,
              }}
              autoComplete="off"
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Delivery executive name"
              size="small"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={deliveryExecutive}
              onChange={(e) => setDeliveryExecutive(e.target.value)}
              inputProps={{
                maxLength: 50,
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="GST Number"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={GSTNumber}
              size="small"
              onChange={(e) => setGSTNumber(e.target.value)}
              inputProps={{
                maxLength: 50,
              }}
              autoComplete="off"
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Email"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={email}
              size="small"
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{
                maxLength: 50,
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Bank Details"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={bankDetails}
              size="small"
              onChange={(e) => setBankDetails(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Address"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={address}
              size="small"
              onChange={(e) => setAddress(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Workshop Address"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={workshopAddress}
              size="small"
              onChange={(e) => setWorkshopAddress(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="PDF Notes"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={PDFNotes}
              size="small"
              onChange={(e) => setPDFNotes(e.target.value)}
              inputProps={{
                maxLength: 1000,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Payment Conditions"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={paymentConditions}
              size="small"
              onChange={(e) => setPaymentConditions(e.target.value)}
              inputProps={{
                maxLength: 5000,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Terms and Conditions"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "white" }}
              margin="normal"
              value={tAndC}
              size="small"
              onChange={(e) => setTAndC(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <p>Holiday List</p>
            <DatePicker
              onChange={(date) => {
                setDate(date);
                addToList(date);
              }}
              value={date}
              style={{ width: "80%", height: 40 }}
            />
            {holidayList.map((item) => {
              return (
                <p style={{ fontSize: 14 }}>
                  {moment(item).format("DD MMM YY")}
                  {"  "}
                  <span onClick={() => removeFromList(item)}>
                    <CloseOutlined />
                  </span>
                </p>
              );
            })}
          </Col>
          {props.common.shopType === "master" && (
            <Col xs={24} sm={10} offset={1}>
              <p>Account Opening Balance</p>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Select
                  style={{
                    width: "35%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={bankName}
                  onChange={(value) => {
                    setBankName(value);
                  }}
                >
                  <Option value="">Select a bank</Option>
                  {props.common.bankList.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
                <TextField
                  label="Opening Balance"
                  variant="outlined"
                  style={{ backgroundColor: "white", width: "35%" }}
                  margin="normal"
                  value={openingBalance}
                  size="small"
                  onChange={(e) => setOpeningBalance(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
                <Button
                  onClick={() => addBalance()}
                  type="primary"
                  disabled={spinnerState}
                >
                  Add Details
                </Button>
              </div>
              {Object.entries(bankBlnc).map(([key, value]) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <p> {value.bankName}</p>
                    <p> {value.openingBalance.toFixed(2)}</p>
                    <p> {value.currentBalance.toFixed(2)}</p>
                    <p onClick={() => removeBalance(key)}>
                      <CloseOutlined />
                    </p>
                  </div>
                );
              })}
            </Col>
          )}
          <Col xs={24} sm={10} offset={1}></Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
