import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker, Button } from "antd";
import { getItemsByTypeFunc, getInvoiceDateOVPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import ReportSales from "./genReportSales";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState({});
  const [outletShopId, setOutletShopId] = useState("");
  const [allOutlets, setAllOutlets] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportSales")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allOutlet = await getItemsByTypeFunc(
      test.test == "outlet"
        ? "Outlet"
        : test.test == "dealer"
        ? "Dealer"
        : "Vendor",
      outletShopId
    );
    setAllOutlets(allOutlet);

    let temp = {};
    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("YYYY") + "-" + EDDate.format("MM");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].year = EDDate.format("YY");
      temp[key].shops = {};
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const key of Object.keys(temp)) {
      let startDate = moment(`${key}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");

      for (const item of allOutlet) {
        let shopId = item.id;
        if (temp[key].shops[shopId] == undefined) {
          temp[key].shops[shopId] = {};
          temp[key].shops[shopId].location = item.location ?? item.shopName;
          temp[key].shops[shopId].total = 0;
        }

        let allSI = await getInvoiceDateOVPagination(
          test.test == "purchase" ? "PurchaseInvoice" : "SalesInvoice",
          outletShopId,
          shopId,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
        );
        if (allSI.length > 0) {
          temp[key].shops[shopId].total = allSI.reduce(
            (sum, bill) => sum + bill.totalAmount,
            0
          );
        }
      }
    }
    setSpinnerState(false);
    setReportList(temp);
  };

  const downloadPDF = async () => {
    let date = `${dateRange[0].format("MMM/YY")}-${dateRange[1].format(
      "MMM/YY"
    )}`;
    await pdf(
      <ReportSales
        allOutlets={allOutlets}
        dateRange={date}
        reportList={JSON.stringify(reportList)}
        from={test.test}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Invoice Report-${dateRange[0].format(
            "MMM/YY"
          )}-${dateRange[1].format("MMM/YY")}.pdf`
        );
      });
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList({});
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div style={{ marginBottom: 20, display: "flex" }}>
          <p style={{ marginRight: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%", marginRight: 20 }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
          {Object.values(reportList).length > 0 && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    alignItems: "center",
                    border: "1px solid black",
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  <p style={{ width: 200 }}>Month</p>
                  <p>
                    {allOutlets.map((item) => {
                      return <div>{item.location ?? item.shopName}</div>;
                    })}
                  </p>
                  <p style={{ width: 200 }}>Total</p>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {Object.entries(reportList).map(([key, value]) => {
                    return (
                      <div
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderTop: "1px solid black",
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        <p style={{ width: 120, fontWeight: "bold" }}>
                          {value.month}-{value.year}
                        </p>
                        <p>
                          {value.shops &&
                            Object.entries(value.shops).map(
                              ([key1, value1]) => {
                                return (
                                  <div style={{ width: 120 }}>
                                    {value1.total.toFixed(2)}
                                  </div>
                                );
                              }
                            )}
                        </p>
                        <p style={{ fontWeight: "bold" }}>
                          {Object.values(value.shops)
                            .reduce((sum, bill) => sum + bill.total, 0)
                            .toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
