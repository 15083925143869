import React, { useState } from "react";
import "../styles/addBanner.css";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Select, Input, Card } from "antd";
import "../styles/addBill.css";
import ZoomImage from "./zoomImage";
import { DeleteOutlined } from "@ant-design/icons";
const { Option } = Select;

function CustDetailsForm(props) {
  const [zoomURL, setZoomURL] = useState("");
  const [isHovered, setHovered] = useState(false);

  const deleteImage = (index) => {
    let temp = [...props.imagesURI];
    let tempBase64 = [...props.imagesBase64];
    temp.splice(index, 1);
    tempBase64.splice(index, 1);
    props.setImagesURI(temp);
    props.setImagesBase64(tempBase64);
  };
  return (
    <>
      <div>
        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            {props.from != "production" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone Number:
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter phone number"
                    value={props.phoneNumber}
                    onChange={(e) => {
                      props.setPhoneNumber(e.target.value);
                      props.setShowSuggestion(false);
                    }}
                    maxLength={10}
                  />
                </div>
              </Col>
            )}
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Billing Address:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter billing address"
                  value={props.address}
                  onChange={(e) => props.setAddress(e.target.value)}
                  maxLength={300}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Shipping Address:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter shipping address"
                  value={props.shippingAddress}
                  onChange={(e) => props.setShippingAddress(e.target.value)}
                  maxLength={300}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Pincode:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter pincode"
                  value={props.pincode}
                  onChange={(e) => props.setPincode(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Customer e-mail:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter customer email"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  maxLength={100}
                />
              </div>
            </Col>
            {props.from != "production" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Lead Source
                  </label>
                  <Select
                    style={{ flex: 1 }}
                    value={props.leadSource}
                    onChange={(value) => {
                      props.setLeadSource(value);
                    }}
                  >
                    <Option value="">Select Source</Option>
                    {props.common.leadSourceList.map((key) => (
                      <Option value={key}>{key}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Customer Notes:
                </label>
                <Input
                  style={{ flex: 1 }}
                  id="customerNotes"
                  placeholder="Enter customer notes"
                  value={props.notes}
                  onChange={(e) => props.setNotes(e.target.value)}
                  maxLength={500}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Delivery Notes:
                </label>
                <Input
                  style={{ flex: 1 }}
                  id="deliveryNotes"
                  placeholder="Enter delivery notes"
                  value={props.deliveryNotes}
                  onChange={(e) => props.setDeliveryNotes(e.target.value)}
                  maxLength={500}
                />
              </div>
            </Col>
            <div style={{ marginLeft: "0.5%" }}>
              <p>Note: Will be displayed on the Invoice</p>
            </div>
          </Row>
          <Row gutter={[16, 16]}>
            {props.imagesURI && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Image:
                  </label>
                  <div>
                    <input
                      type="file"
                      multiple
                      name="myImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={props.onImageChange}
                    />
                    <div
                      style={{
                        marginTop: -25,
                        marginLeft: "78%",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      {props.imagesURI.map((uri, index) => (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <img
                            key={index}
                            src={uri}
                            alt={`Image ${index}`}
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              border: "1px solid #ccc",
                            }}
                            onClick={() => {
                              setZoomURL(uri);
                            }}
                          />
                          {isHovered && (
                            <DeleteOutlined
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                background: "black",
                                color: "white",
                                border: "none",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteImage(index);
                              }}
                            >
                              Delete
                            </DeleteOutlined>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {props.imagesURIInstall &&
              (props.status == "Installed" ||
                props.status == "Assign To RF") && (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      padding: "8px 0",
                    }}
                  >
                    <label
                      style={{
                        width: "120px",
                        marginRight: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Install Image:
                    </label>
                    <div>
                      <input
                        type="file"
                        multiple
                        name="myImage"
                        accept=".png, .jpg, .jpeg"
                        onChange={props.onImageChangeInstall}
                      />
                      <div
                        style={{
                          marginTop: -25,
                          marginLeft: "78%",
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        {props.imagesURIInstall.map((uri, index) => (
                          <img
                            key={index}
                            src={uri}
                            alt={`Image ${index}`}
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              border: "1px solid #ccc",
                            }}
                            onClick={() => {
                              setZoomURL(uri);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {props.from === "quotation" && props.status === "Follow Up" && (
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Customer visit is on{" "}
                  {moment(props.visitDate, "YYYY-MM-DD").format("DD MMM")} at{" "}
                  {moment(props.visitTime, "HH:mm:ss").format("hh:mm A")}
                </label>
              )}
            </Col>
            <Col span={12}>
              {props.from === "order" &&
                props.status != "delivered" &&
                props.estimatedDelivery != "" && (
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Estimated delivery date:{" "}
                    {props.estimatedDelivery.format("DD MMM")}
                  </label>
                )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {(props.from === "order" || props.from === "install") &&
                props.installDt != "" && (
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Installation date: {props.installDt.format("DD MMM")}
                  </label>
                )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {props.from === "order" &&
                props.status != "delivered" &&
                props.deliveryExecutive != "" && (
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Delivery executive: {props.deliveryExecutive}
                  </label>
                )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {props.from === "quotation" &&
                props.test != "addNewQuotation" && (
                  <label
                    style={{
                      width: "140px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Enquiry Date:{" "}
                    {moment(props.date, "YYYY-MM-DD").format("DD MMM")}
                  </label>
                )}
            </Col>
            <Col span={12}>
              {props.test != "addNewQuotation" && (
                <label
                  style={{
                    width: "180px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Added By: {props.addedBy}
                </label>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {props.prodStatus != "" && (
                <label
                  style={{
                    width: "180px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Production Status: {props.prodStatus}
                </label>
              )}
            </Col>
            <Col span={12}>
              {props.oldDetails.location != "" && (
                <label
                  style={{
                    width: "180px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Lead Location : {props.oldDetails.location}
                </label>
              )}
            </Col>
          </Row>
          {(props.from == "quotation" || props.from == "order") && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                {Object.entries(props.quotationTrail).length > 0 && (
                  <span
                    style={{
                      width: "180px",
                      marginRight: "8px",
                      fontWeight: "bold",
                      color: "dodgerblue",
                    }}
                    onClick={() => props.setHistoryModal(true)}
                  >
                    {props.from === "quotation"
                      ? "Quotation History"
                      : "Order History"}
                  </span>
                )}
              </Col>
              <Col span={12}></Col>
            </Row>
          )}
        </Card>
        {zoomURL != "" && (
          <ZoomImage setZoomURL={setZoomURL} zoomURL={zoomURL} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(CustDetailsForm);
