import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import {
  getItemsByDatePagination,
  getItemsdatePagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { DatePicker, Card, Button } from "antd";
import { SpinnerCircular } from "spinners-react";
import moment from "moment";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;

function ReportProfitLoss() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [spinnerState, setSpinnerState] = useState(false);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getData(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportProfitLoss")) navigate("/homePage");
  }, []);

  const headers = [
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
    { label: "Order", key: "order" },
    { label: "PI", key: "PI" },
    { label: "Expense", key: "expense" },
  ];

  const getData = async (outletShopId) => {
    setSpinnerState(true);
    let temp = {};
    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].yr = EDDate.format("YY");
      temp[key].order = 0;
      temp[key].PI = 0;
      temp[key].expense = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");

      let allOrder = await getItemsByDatePagination(
        "Order",
        outletShopId,
        startDate,
        endDate
      );
      value.order = allOrder.reduce((sum, bill) => sum + bill.totalAmount, 0);

      let allInvoice = await getItemsdatePagination(
        "PurchaseInvoice",
        outletShopId,
        startDate,
        endDate
      );
      console.log(allInvoice.length);
      value.PI = allInvoice.reduce((sum, bill) => sum + bill.totalAmount, 0);

      let allExp = await getItemsdatePagination(
        "Expense",
        outletShopId,
        startDate,
        endDate
      );
      console.log(allExp.length);
      value.expense = allExp.reduce((sum, bill) => sum + bill.totalAmount, 0);
    }
    // Convert to an array and sort it
    let sortedArray = Object.values(temp).sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      return b.mnth - a.mnth;
    });

    setReportList(sortedArray);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") getData(outletShopId);
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <p style={{ paddingRight: 20 }}>Select Date Range: </p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headers}
                filename={
                  "ProfitLoss-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Card style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: 200, fontWeight: "bold" }}>
              <p>Month</p>
              <p>Sales (WO total): </p>
              <p>Purshase Invoice Total: </p>
              <p>Expense Total: </p>
              <p>Profit: </p>
            </div>
            {reportList.map((item) => {
              return (
                <>
                  <div style={{ width: 200, textAlign: "right" }}>
                    <p style={{ fontWeight: "bold" }}>
                      {item.month}-{item.year}
                    </p>
                    <p>{item.order.toFixed(2)}</p>
                    <p>{item.PI.toFixed(2)}</p>
                    <p>{item.expense.toFixed(2)}</p>
                    <p style={{ fontWeight: "bold" }}>
                      {(item.order - item.PI - item.expense).toFixed(2)}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </Card>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportProfitLoss);
