/* eslint-disable */
// this is an auto generated file. This will be overwritten
import gql from "graphql-tag";

export const createUserDetails = gql`
  mutation createUserDetails(
    $id: String
    $email: String
    $shopName: String
    $shopType: String
    $role: String
    $randomId: String
    $shopId: String
    $outletShopId: String
    $type: String
    $location: String
  ) {
    createUserDetails(
      input: {
        id: $id
        email: $email
        shopName: $shopName
        shopType: $shopType
        role: $role
        randomId: $randomId
        shopId: $shopId
        outletShopId: $outletShopId
        type: $type
        location: $location
      }
    ) {
      id
    }
  }
`;

export const addItem = gql`
  mutation addItem(
    $itemName: String
    $itemDescription: String
    $itemUOM: String
    $type: String
    $addedBy: String
    $componentList: AWSJSON
    $incomingQuantity: Float
    $remainingQuantity: Float
    $rawMaterial: Float
    $soldQuantity: Float
    $lowStockValue: Float
    $shopId: String
    $location: String
    $address: String
    $shippingAddress: String
    $phoneNumber: String
    $outletType: String
    $randomId: String
    $outletShopId: String
    $shopName: String
    $shopType: String
    $country: String
    $currency: String
    $name: String
    $email: String
    $notes: String
    $deliveryNotes: String
    $orderDetails: AWSJSON
    $totalAmount: Float
    $time: String
    $subTotal: Float
    $GST: Float
    $GSTAmount: Float
    $IGST: Float
    $IGSTAmount: Float
    $TDS: Float
    $TDSAmount: Float
    $discountValue: Float
    $discountType: String
    $discountAmount: Float
    $stockReceived: String
    $itemId: String
    $quantity: String
    $from: String
    $trackingID: String
    $action: String
    $updatedBy: String
    $followUpDate: String
    $GSTNumber: String
    $vendorId: String
    $vendorName: String
    $PO_ID: String
    $followUpStatus: String
    $billPrefix: String
    $calBasedOn: String
    $unitPerPanel: String
    $paymentDetails: AWSJSON
    $quotationTrail: AWSJSON
    $pincode: String
    $role: String
    $group: String
    $formula: String
    $explaination: String
    $example: String
    $formulaList: AWSJSON
    $itemTags: [String]
    $symbol: String
    $addedByOutletShopId: String
    $addedByLocation: String
    $leadId: String
    $followUpLog: AWSJSON
    $lockForUpdate: String
    $masterPP: String
    $itemImages: [String]
    $leadSource: String
    $itemCode: String
    $DOB: String
    $DOJ: String
    $gender: String
    $extraCharges: Float
    $quantityLog: AWSJSON
    $color: String
    $orderId: String
    $quotationId: String
    $visitDate: String
    $visitTime: String
    $paymentDueDate: String
    $tinyURL: String
    $PDFNotes: String
    $paymentConditions: String
    $tAndC: String
    $itemFile: String
    $assignToLocation: String
    $assignToUser: String
    $assignToUserId: String
    $updatedDate: String
    $updatedTime: String
    $outletSQFTMap: AWSJSON
    $orderBy: String
    $orderLocation: String
    $estimatedDelivery: String
    $deliveryExecutive: String
    $complaint: String
    $priority: String
    $paymentStatus: String
    $HSN: String
    $paymentDone: Float
    $paymentPending: Float
    $documents: AWSJSON
    $emgDetails: AWSJSON
    $bankDetailsMap: AWSJSON
    $salaryDetails: AWSJSON
    $salaryCredited: AWSJSON
    $empId: String
    $year: String
    $month: String
    $attendance: AWSJSON
    $installDt: String
    $profileList: AWSJSON
    $attndDetails: AWSJSON
    $earning: Float
    $deductions: Float
    $salary: Float
    $paymentMode: String
    $date: String
    $outletId: String
    $bankName: String
    $installmentCount: Float
    $installmentDone: Float
    $installmentLog: AWSJSON
    $installmentAmount: Float
    $category: String
    $state: String
    $city: String
    $balance: Float
  ) {
    addItem(
      input: {
        itemName: $itemName
        itemDescription: $itemDescription
        itemUOM: $itemUOM
        addedBy: $addedBy
        type: $type
        componentList: $componentList
        incomingQuantity: $incomingQuantity
        remainingQuantity: $remainingQuantity
        rawMaterial: $rawMaterial
        soldQuantity: $soldQuantity
        lowStockValue: $lowStockValue
        shopId: $shopId
        location: $location
        address: $address
        shippingAddress: $shippingAddress
        phoneNumber: $phoneNumber
        outletType: $outletType
        randomId: $randomId
        outletShopId: $outletShopId
        shopName: $shopName
        shopType: $shopType
        country: $country
        currency: $currency
        name: $name
        email: $email
        notes: $notes
        deliveryNotes: $deliveryNotes
        orderDetails: $orderDetails
        totalAmount: $totalAmount
        time: $time
        subTotal: $subTotal
        GST: $GST
        GSTAmount: $GSTAmount
        IGST: $IGST
        IGSTAmount: $IGSTAmount
        TDS: $TDS
        TDSAmount: $TDSAmount
        discountValue: $discountValue
        discountType: $discountType
        discountAmount: $discountAmount
        stockReceived: $stockReceived
        itemId: $itemId
        quantity: $quantity
        from: $from
        trackingID: $trackingID
        action: $action
        updatedBy: $updatedBy
        followUpDate: $followUpDate
        GSTNumber: $GSTNumber
        vendorId: $vendorId
        vendorName: $vendorName
        PO_ID: $PO_ID
        followUpStatus: $followUpStatus
        billPrefix: $billPrefix
        calBasedOn: $calBasedOn
        unitPerPanel: $unitPerPanel
        paymentDetails: $paymentDetails
        quotationTrail: $quotationTrail
        pincode: $pincode
        role: $role
        group: $group
        formula: $formula
        explaination: $explaination
        example: $example
        formulaList: $formulaList
        itemTags: $itemTags
        symbol: $symbol
        addedByOutletShopId: $addedByOutletShopId
        addedByLocation: $addedByLocation
        leadId: $leadId
        followUpLog: $followUpLog
        lockForUpdate: $lockForUpdate
        masterPP: $masterPP
        itemImages: $itemImages
        leadSource: $leadSource
        itemCode: $itemCode
        DOB: $DOB
        DOJ: $DOJ
        gender: $gender
        extraCharges: $extraCharges
        quantityLog: $quantityLog
        color: $color
        orderId: $orderId
        quotationId: $quotationId
        visitDate: $visitDate
        visitTime: $visitTime
        paymentDueDate: $paymentDueDate
        tinyURL: $tinyURL
        PDFNotes: $PDFNotes
        paymentConditions: $paymentConditions
        tAndC: $tAndC
        itemFile: $itemFile
        assignToLocation: $assignToLocation
        assignToUser: $assignToUser
        assignToUserId: $assignToUserId
        updatedDate: $updatedDate
        updatedTime: $updatedTime
        outletSQFTMap: $outletSQFTMap
        orderBy: $orderBy
        orderLocation: $orderLocation
        estimatedDelivery: $estimatedDelivery
        deliveryExecutive: $deliveryExecutive
        complaint: $complaint
        priority: $priority
        paymentStatus: $paymentStatus
        HSN: $HSN
        paymentDone: $paymentDone
        paymentPending: $paymentPending
        documents: $documents
        emgDetails: $emgDetails
        bankDetailsMap: $bankDetailsMap
        salaryDetails: $salaryDetails
        salaryCredited: $salaryCredited
        empId: $empId
        year: $year
        month: $month
        attendance: $attendance
        installDt: $installDt
        profileList: $profileList
        attndDetails: $attndDetails
        earning: $earning
        deductions: $deductions
        salary: $salary
        paymentMode: $paymentMode
        date: $date
        outletId: $outletId
        bankName: $bankName
        installmentCount: $installmentCount
        installmentDone: $installmentDone
        installmentLog: $installmentLog
        installmentAmount: $installmentAmount
        category: $category
        state: $state
        city: $city
        balance: $balance
      }
    ) {
      id
    }
  }
`;

export const editItem = gql`
  mutation editItem(
    $id: String
    $itemName: String
    $itemDescription: String
    $updatedBy: String
    $componentList: AWSJSON
    $incomingQuantity: Float
    $remainingQuantity: Float
    $rawMaterial: Float
    $soldQuantity: Float
    $lowStockValue: Float
    $location: String
    $address: String
    $shippingAddress: String
    $phoneNumber: String
    $outletType: String
    $shopName: String
    $country: String
    $currency: String
    $name: String
    $email: String
    $notes: String
    $deliveryNotes: String
    $orderDetails: AWSJSON
    $totalAmount: Float
    $subTotal: Float
    $GST: Float
    $GSTAmount: Float
    $IGST: Float
    $IGSTAmount: Float
    $TDS: Float
    $TDSAmount: Float
    $discountValue: Float
    $discountType: String
    $discountAmount: Float
    $followUpStatus: String
    $followUpLog: AWSJSON
    $followedUpDate: String
    $followUpDate: String
    $GSTNumber: String
    $vendorId: String
    $vendorName: String
    $PO_ID: String
    $status: String
    $billPrefix: String
    $visitDate: String
    $visitTime: String
    $rejectReason: String
    $paymentDueDate: String
    $pincode: String
    $paymentDetails: AWSJSON
    $quotationTrail: AWSJSON
    $role: String
    $group: String
    $formulaList: AWSJSON
    $workshopAddress: String
    $PDFNotes: String
    $paymentConditions: String
    $tAndC: String
    $symbol: String
    $assignToLocation: String
    $assignToUser: String
    $outletShopId: String
    $assignToUserId: String
    $itemImages: [String]
    $leadSource: String
    $DOB: String
    $DOJ: String
    $gender: String
    $extraCharges: Float
    $updatedDate: String
    $driverDetails: AWSJSON
    $itemFile: String
    $itemImagesInstall: [String]
    $masterOrderId: String
    $prodOrderId: String
    $bankDetails: String
    $itemUOM: String
    $calBasedOn: String
    $unitPerPanel: String
    $formula: String
    $explaination: String
    $example: String
    $deliveryExecutive: String
    $masterPP: String
    $orderId: String
    $complaint: String
    $priority: String
    $HSN: String
    $documents: AWSJSON
    $emgDetails: AWSJSON
    $bankDetailsMap: AWSJSON
    $salaryDetails: AWSJSON
    $salaryCredited: AWSJSON
    $empId: String
    $year: String
    $attendance: AWSJSON
    $from: String
    $holidayList: [String]
    $attndDetails: AWSJSON
    $earning: Float
    $deductions: Float
    $salary: Float
    $expCatList: [String]
    $paymentMode: String
    $date: String
    $bankName: String
    $paymentStatus: String
    $paymentDone: Float
    $paymentPending: Float
    $tokenWeb: String
    $type: String
    $category: String
    $state: String
    $city: String
    $bankBlnc: AWSJSON
  ) {
    editItem(
      input: {
        id: $id
        itemName: $itemName
        itemDescription: $itemDescription
        updatedBy: $updatedBy
        componentList: $componentList
        incomingQuantity: $incomingQuantity
        remainingQuantity: $remainingQuantity
        rawMaterial: $rawMaterial
        soldQuantity: $soldQuantity
        lowStockValue: $lowStockValue
        location: $location
        address: $address
        shippingAddress: $shippingAddress
        phoneNumber: $phoneNumber
        outletType: $outletType
        shopName: $shopName
        country: $country
        currency: $currency
        name: $name
        email: $email
        notes: $notes
        deliveryNotes: $deliveryNotes
        orderDetails: $orderDetails
        totalAmount: $totalAmount
        subTotal: $subTotal
        GST: $GST
        GSTAmount: $GSTAmount
        IGST: $IGST
        IGSTAmount: $IGSTAmount
        TDS: $TDS
        TDSAmount: $TDSAmount
        discountValue: $discountValue
        discountType: $discountType
        discountAmount: $discountAmount
        followUpStatus: $followUpStatus
        followUpLog: $followUpLog
        followedUpDate: $followedUpDate
        followUpDate: $followUpDate
        GSTNumber: $GSTNumber
        vendorId: $vendorId
        vendorName: $vendorName
        PO_ID: $PO_ID
        status: $status
        billPrefix: $billPrefix
        visitDate: $visitDate
        visitTime: $visitTime
        rejectReason: $rejectReason
        paymentDueDate: $paymentDueDate
        pincode: $pincode
        paymentDetails: $paymentDetails
        quotationTrail: $quotationTrail
        role: $role
        group: $group
        formulaList: $formulaList
        workshopAddress: $workshopAddress
        PDFNotes: $PDFNotes
        paymentConditions: $paymentConditions
        tAndC: $tAndC
        symbol: $symbol
        assignToLocation: $assignToLocation
        assignToUser: $assignToUser
        outletShopId: $outletShopId
        assignToUserId: $assignToUserId
        itemImages: $itemImages
        leadSource: $leadSource
        DOB: $DOB
        DOJ: $DOJ
        gender: $gender
        extraCharges: $extraCharges
        updatedDate: $updatedDate
        driverDetails: $driverDetails
        itemFile: $itemFile
        itemImagesInstall: $itemImagesInstall
        masterOrderId: $masterOrderId
        prodOrderId: $prodOrderId
        bankDetails: $bankDetails
        itemUOM: $itemUOM
        calBasedOn: $calBasedOn
        unitPerPanel: $unitPerPanel
        formula: $formula
        explaination: $explaination
        example: $example
        deliveryExecutive: $deliveryExecutive
        masterPP: $masterPP
        orderId: $orderId
        complaint: $complaint
        priority: $priority
        HSN: $HSN
        documents: $documents
        emgDetails: $emgDetails
        bankDetailsMap: $bankDetailsMap
        salaryDetails: $salaryDetails
        salaryCredited: $salaryCredited
        empId: $empId
        year: $year
        attendance: $attendance
        from: $from
        holidayList: $holidayList
        attndDetails: $attndDetails
        earning: $earning
        deductions: $deductions
        salary: $salary
        expCatList: $expCatList
        paymentMode: $paymentMode
        date: $date
        bankName: $bankName
        paymentStatus: $paymentStatus
        paymentDone: $paymentDone
        paymentPending: $paymentPending
        tokenWeb: $tokenWeb
        type: $type
        category: $category
        state: $state
        city: $city
        bankBlnc: $bankBlnc
      }
    ) {
      id
    }
  }
`;

export const updateBillInventory = gql`
  mutation updateBillInventory($id: String) {
    updateBillInventory(input: { id: $id }) {
      orderDetails
    }
  }
`;

export const updateSTInventory = gql`
  mutation updateSTInventory($updatedBy: String, $id: String) {
    updateSTInventory(input: { updatedBy: $updatedBy, id: $id }) {
      componentList
    }
  }
`;

export const updateRawInventory = gql`
  mutation updateRawInventory($updatedBy: String, $id: String) {
    updateRawInventory(input: { updatedBy: $updatedBy, id: $id }) {
      componentList
    }
  }
`;

export const updatePIInventory = gql`
  mutation updatePIInventory($updatedBy: String, $id: String) {
    updatePIInventory(input: { updatedBy: $updatedBy, id: $id }) {
      componentList
    }
  }
`;

export const updatePOInventory = gql`
  mutation updatePOInventory($updatedBy: String, $id: String, $action: String) {
    updatePOInventory(
      input: { updatedBy: $updatedBy, id: $id, action: $action }
    ) {
      componentList
    }
  }
`;

export const updateInventory = gql`
  mutation updateInventory(
    $id: String
    $inventoryQuantity: Float
    $lowStockValue: Float
    $updatedBy: String
    $time: String
    $quantityLog: AWSJSON
    $itemTags: [String]
    $rawMaterial: Float
  ) {
    updateInventory(
      input: {
        id: $id
        inventoryQuantity: $inventoryQuantity
        lowStockValue: $lowStockValue
        updatedBy: $updatedBy
        time: $time
        quantityLog: $quantityLog
        itemTags: $itemTags
        rawMaterial: $rawMaterial
      }
    ) {
      id
    }
  }
`;

export const generatePDF = gql`
  mutation generatePDF($id: String) {
    generatePDF(input: { id: $id }) {
      id
    }
  }
`;

export const updateStatus = gql`
  mutation updateStatus(
    $id: String
    $updatedBy: String
    $visitDate: String
    $visitTime: String
    $status: String
    $rejectReason: String
    $name: String
    $phoneNumber: String
    $email: String
    $address: String
    $shippingAddress: String
    $notes: String
    $deliveryNotes: String
    $orderDetails: AWSJSON
    $totalAmount: Float
    $subTotal: Float
    $GST: Float
    $GSTAmount: Float
    $discountValue: Float
    $discountType: String
    $discountAmount: Float
    $time: String
    $paymentDueDate: String
    $pincode: String
    $randomId: String
    $shopId: String
    $outletShopId: String
    $quotationTrail: AWSJSON
    $leadId: String
    $outletType: String
    $location: String
    $PDFNotes: String
    $paymentConditions: String
    $tAndC: String
    $paymentDetails: AWSJSON
    $itemImages: [String]
    $extraCharges: Float
    $leadSource: String
  ) {
    updateStatus(
      input: {
        id: $id
        updatedBy: $updatedBy
        visitDate: $visitDate
        visitTime: $visitTime
        status: $status
        rejectReason: $rejectReason
        name: $name
        phoneNumber: $phoneNumber
        email: $email
        address: $address
        shippingAddress: $shippingAddress
        notes: $notes
        deliveryNotes: $deliveryNotes
        orderDetails: $orderDetails
        totalAmount: $totalAmount
        subTotal: $subTotal
        GST: $GST
        GSTAmount: $GSTAmount
        discountValue: $discountValue
        discountType: $discountType
        discountAmount: $discountAmount
        time: $time
        paymentDueDate: $paymentDueDate
        pincode: $pincode
        randomId: $randomId
        shopId: $shopId
        outletShopId: $outletShopId
        quotationTrail: $quotationTrail
        leadId: $leadId
        outletType: $outletType
        location: $location
        PDFNotes: $PDFNotes
        paymentConditions: $paymentConditions
        tAndC: $tAndC
        paymentDetails: $paymentDetails
        itemImages: $itemImages
        extraCharges: $extraCharges
        leadSource: $leadSource
      }
    ) {
      id
    }
  }
`;

export const updateOrderStatus = gql`
  mutation updateOrderStatus(
    $id: String
    $updatedBy: String
    $status: String
    $rejectReason: String
    $estimatedDelivery: String
    $deliveryExecutive: String
    $installDt: String
  ) {
    updateOrderStatus(
      input: {
        id: $id
        updatedBy: $updatedBy
        status: $status
        rejectReason: $rejectReason
        estimatedDelivery: $estimatedDelivery
        deliveryExecutive: $deliveryExecutive
        installDt: $installDt
      }
    ) {
      id
    }
  }
`;

export const updateGroup = gql`
  mutation updateGroup(
    $id: String
    $itemName: String
    $itemDescription: String
    $outletShopId: String
    $componentList: AWSJSON
    $updatedBy: String
    $itemTags: [String]
    $shopId: String
    $itemImages: [String]
    $pricePerSQFT: String
    $shopId: String
    $itemCode: String
    $outletSQFTMap: AWSJSON
    $HSN: String
    $profileList: AWSJSON
  ) {
    updateGroup(
      input: {
        id: $id
        itemName: $itemName
        itemDescription: $itemDescription
        outletShopId: $outletShopId
        componentList: $componentList
        updatedBy: $updatedBy
        itemTags: $itemTags
        shopId: $shopId
        itemImages: $itemImages
        pricePerSQFT: $pricePerSQFT
        shopId: $shopId
        itemCode: $itemCode
        outletSQFTMap: $outletSQFTMap
        HSN: $HSN
        profileList: $profileList
      }
    ) {
      id
    }
  }
`;

export const paymentsOutlet = gql`
  mutation paymentsOutlet(
    $id: String
    $updatedBy: String
    $installmentAmount: Float
    $paymentMode: String
    $paymentId: String
    $shopId: String
    $outletShopId: String
    $date: String
    $bankName: String
    $paymentType: String
  ) {
    paymentsOutlet(
      input: {
        id: $id
        updatedBy: $updatedBy
        installmentAmount: $installmentAmount
        paymentMode: $paymentMode
        paymentId: $paymentId
        shopId: $shopId
        outletShopId: $outletShopId
        date: $date
        bankName: $bankName
        paymentType: $paymentType
      }
    ) {
      id
    }
  }
`;

export const paymentsVendor = gql`
  mutation paymentsVendor(
    $id: String
    $updatedBy: String
    $installmentAmount: Float
    $paymentMode: String
    $paymentId: String
    $shopId: String
    $outletShopId: String
    $date: String
    $bankName: String
  ) {
    paymentsVendor(
      input: {
        id: $id
        updatedBy: $updatedBy
        installmentAmount: $installmentAmount
        paymentMode: $paymentMode
        paymentId: $paymentId
        shopId: $shopId
        outletShopId: $outletShopId
        date: $date
        bankName: $bankName
      }
    ) {
      id
    }
  }
`;

export const notification = gql`
  mutation notification(
    $message: String
    $title: String
    $tokenArray: [String]
  ) {
    notification(message: $message, title: $title, tokenArray: $tokenArray) {
      id
    }
  }
`;

export const loanPayment = gql`
  mutation loanPayment($id: String, $updatedBy: String) {
    loanPayment(input: { id: $id, updatedBy: $updatedBy }) {
      id
    }
  }
`;

export const updateBankBlnc = gql`
  mutation updateBankBlnc(
    $shopId: String
    $bankName: String
    $installmentAmount: Float
    $from: String
    $updatedBy: String
  ) {
    updateBankBlnc(
      input: {
        shopId: $shopId
        bankName: $bankName
        installmentAmount: $installmentAmount
        from: $from
        updatedBy: $updatedBy
      }
    ) {
      id
    }
  }
`;
