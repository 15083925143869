import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button, DatePicker } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { getAllItemsPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { CSVLink } from "react-csv";

function ListInvoice() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [date, setDate] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [dashboard, setDashboard] = useState({
    dueToday: 0,
    dueCount: 0,
    overDue: 0,
    overDueCount: 0,
    paid: 0,
    paidCount: 0,
    total: 0,
    totalCount: 0,
  });

  const headers = [
    { label: "Id", key: "randomId" },
    { label: "WO No.", key: "PO_ID" },
    { label: "Date", key: "date" },
    { label: "Outlet", key: "location" },
    { label: "Status", key: "paymentStatus" },
    { label: "Payment Due Date", key: "paymentDueDate" },
    { label: "Sub Total", key: "subTotal" },
    { label: "GST Amount", key: "GSTAmount" },
    { label: "Payment Done", key: "paymentDone" },
    { label: "Payment Pending", key: "paymentPending" },
    { label: "Total", key: "totalAmount" },
  ];

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "15%",
      render: (randomId, record) => (
        <a
          style={{
            color:
              moment(record.paymentDueDate).isBefore(moment(), "day") == true
                ? "Red"
                : "Black",
          }}
        >
          {randomId}
        </a>
      ),
    },
    {
      title: "WO No.",
      dataIndex: "PO_ID",
      key: "PO_ID",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      width: "15%",
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Outlet",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      ellipsis: true,
      width: "15%",
      render: (paymentStatus) => (paymentStatus == "false" ? "Due" : "Done"),
    },
    {
      title: "Pay Due",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      ellipsis: true,
      width: "15%",
      render: (paymentDueDate) => moment(paymentDueDate).format("DD MMM YY"),
    },
    {
      title: "Sub-Total",
      dataIndex: "subTotal",
      key: "subTotal",
      ellipsis: true,
      width: "15%",
      render: (subTotal) => parseFloat(subTotal).toFixed(2),
    },
    {
      title: "GST",
      dataIndex: "GSTAmount",
      key: "GSTAmount",
      ellipsis: true,
      width: "15%",
      render: (GSTAmount) => parseFloat(GSTAmount).toFixed(2),
    },
    {
      title: "Pay Done",
      dataIndex: "paymentDone",
      key: "paymentDone",
      ellipsis: true,
      width: "15%",
      render: (paymentDone) => parseFloat(paymentDone).toFixed(2),
    },
    {
      title: "Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      ellipsis: true,
      width: "15%",
      render: (paymentPending) => parseFloat(paymentPending).toFixed(2),
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "15%",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "15%",
      render: (id) => (
        <a onClick={() => navigate(`../AddInvoice/${id}`)}>View</a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Invoice")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getAllItemsPagination("SalesInvoice", outletShopId);
    let temp = {
      dueToday: 0,
      dueCount: 0,
      overDue: 0,
      overDueCount: 0,
      paid: 0,
      paidCount: 0,
      total: 0,
      totalCount: 0,
    };

    if (date != "" && date != null)
      allItems = allItems.filter(
        (item) => item.paymentDueDate == date.format("YYYY-MM-DD")
      );

    allItems.map((item) => {
      temp.total = temp.total + item.totalAmount;
      temp.totalCount = temp.totalCount + 1;
      temp.paid = temp.paid + item.paymentDone;
      if (item.paymentStatus == "false") {
        if (moment(item.paymentDueDate).isSame(moment(), "day")) {
          temp.dueToday = temp.dueToday + item.paymentPending;
          temp.dueCount = temp.dueCount + 1;
        }
        if (moment(item.paymentDueDate).isBefore(moment(), "day")) {
          temp.overDue = temp.overDue + item.paymentPending;
          temp.overDueCount = temp.overDueCount + 1;
        }
      } else {
        temp.paidCount = temp.paidCount + 1;
      }
    });

    allItems.sort(
      (a, b) => moment(b.date, "YYYY-MM-DD") - moment(a.date, "YYYY-MM-DD")
    );
    setDashboard(temp);
    setAllItems(allItems);
  };

  useEffect(() => {
    if (outletShopId != "") getAllItemsByType(outletShopId);
  }, [date]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 15,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            name="search"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label>Due Date: </label>
            <DatePicker
              placeholder="Due Date"
              onChange={(date) => setDate(date)}
              value={date}
              style={{ width: "80%", height: 40, marginTop: 15 }}
            />
          </div>
          {allItems.length > 0 && (
            <Button type="primary">
              <CSVLink data={allItems} headers={headers} filename={"SI.csv"}>
                Export to CSV
              </CSVLink>
            </Button>
          )}
          <Button type="primary">
            <Link to="../ListVendor/dealer">List Dealers</Link>
          </Button>
          <Button type="primary">
            <Link to="../AddInvoice/addNewRecord">
              <PlusOutlined /> New Invoice
            </Link>
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 15,
            fontSize: 18,
          }}
        >
          <label>
            Due: {dashboard.dueToday.toFixed(2)} ({dashboard.dueCount})
          </label>
          <label>
            Over Due: {dashboard.overDue.toFixed(2)} ({dashboard.overDueCount})
          </label>
          <label>
            Paid: {dashboard.paid.toFixed(2)} ({dashboard.paidCount})
          </label>
          <label>
            Total: {dashboard.total.toFixed(2)} ({dashboard.totalCount})
          </label>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.location
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            (item.PO_ID ?? "")
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListInvoice);
