import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Switch } from "antd";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getAllItemsPagination100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
const { RangePicker } = DatePicker;

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [showAll, setShowAll] = useState(false);
  const [showCount, setShowCount] = useState({});

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "20%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/production`)}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "25%",
      render: (name, record) => (
        <a
          style={{
            marginTop: -5,
            marginBottom: -5,
            color:
              record.status == "Delivered" ||
              record.status == "Installed" ||
              record.status == "Dispatched"
                ? "Black"
                : moment(record.estimatedDelivery).diff(
                    moment(new Date()).format("YYYY-MM-DD"),
                    "days"
                  ) >= 0
                ? "Black"
                : "Red",
            fontWeight: record.status === "live" ? "bold" : "normal",
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "13%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Delivery Date",
      dataIndex: "estimatedDelivery",
      key: "estimatedDelivery",
      ellipsis: true,
      width: "15%",
      render: (estimatedDelivery) =>
        moment(estimatedDelivery, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "In Days",
      dataIndex: "estimatedDelivery",
      key: "estimatedDelivery",
      ellipsis: true,
      width: "13%",
      render: (estimatedDelivery, record) =>
        record.status == "Delivered" || record.status == "Installed"
          ? "NA"
          : moment(estimatedDelivery).diff(
              moment(new Date()).format("YYYY-MM-DD"),
              "days"
            ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: "15%",
      render: (status) => (status == "live" ? "New" : status),
    },
    {
      title: "Shipping Address",
      dataIndex: "shippingAddress",
      key: "shippingAddress",
      ellipsis: true,
      width: "11%",
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "View",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "10%",
            render: (id) => (
              <a onClick={() => navigate(`../SqftCompQuot/${id}/production`)}>
                View
              </a>
            ),
          },
        ]
      : []),
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Profile",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "10%",
            render: (id) => (
              <a onClick={() => navigate(`../CalProfileMultiple/${id}`)}>
                Count
              </a>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          setShopId(user.attributes["custom:shopId"]);
          await getAllItemsByType(
            user.attributes["custom:shopId"],
            user.attributes["custom:outletShopId"]
          );
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Production")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId, outletShopId) => {
    let allItems = [];

    if (showAll) allItems = await getAllItemsPagination("Production", shopId);
    else allItems = await getAllItemsPagination100("Production", shopId);

    console.log(allItems.length);

    if (props.common.shopType == "outlet")
      allItems = allItems.filter((item) => item.orderBy == outletShopId);

    console.log(allItems.length);

    if (dateRange[0] != "" && dateRange[1] != "") {
      let startDate = dateRange[0].format("YYYY-MM-DD");
      let endDate = dateRange[1].format("YYYY-MM-DD");

      allItems = allItems.filter(
        (item) =>
          moment(item.estimatedDelivery, "YYYY-MM-DD").isSameOrAfter(
            startDate
          ) &&
          moment(item.estimatedDelivery, "YYYY-MM-DD").isSameOrBefore(endDate)
      );
    }

    let temp = {
      New: 0,
      Cutting: 0,
      Mesh: 0,
      Packed: 0,
      Dispatched: 0,
    };
    temp.New = allItems.filter((item) => item.status == "live").length;
    temp.Cutting = allItems.filter(
      (item) => item.status == "Cutting Complete"
    ).length;
    temp.Mesh = allItems.filter((item) => item.status == "Mesh Process").length;
    temp.Packed = allItems.filter((item) => item.status == "Packed").length;
    temp.Dispatched = allItems.filter(
      (item) => item.status == "Dispatched"
    ).length;
    setShowCount(temp);
    setAllItems(allItems);
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      setShowCount({});
      await getAllItemsByType(shopId, outletShopId);
    };
    if (shopId != "" && outletShopId != "") getData();
  }, [dateRange, showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            name="search"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <TextField
            select
            margin="normal"
            label="Select Status"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white", marginLeft: 30 }}
            value={status}
            size="small"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            autoComplete="off"
          >
            <MenuItem key="live" value="live">
              New
            </MenuItem>
            {props.common.prodStatusArray.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
          <RangePicker
            style={{ width: "20%", marginLeft: 30 }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show Few" : "Show all Items"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(showCount).map(([key, value]) => {
            return (
              <p style={{ fontWeight: "bold" }}>
                {key} : {value}
              </p>
            );
          })}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == "" && status == ""
                      ? allItems
                      : term != "" && status == ""
                      ? allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                      : term == "" && status != ""
                      ? allItems.filter((item) => item.status == status)
                      : allItems
                          .filter((item) => item.status == status)
                          .filter(
                            (item) =>
                              item.randomId
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.name
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1
                          )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
