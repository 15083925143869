import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create Document Component
export default function ReportSales(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>
              {props.from == "outlet"
                ? "Outlet"
                : props.from == "dealer"
                ? "Dealer"
                : "Vendor"}{" "}
              Invoice Report
            </Text>
          </View>
          <View style={{ borderWidth: 1, fontSize: 10, alignItems: "center" }}>
            <Text>ROYAL FABRICS</Text>
            <Text>312/44 THAMBU CHETTY STREET</Text>
            <Text>MANNADY,CHENNAI -600001</Text>
            <Text>8015611126 / 42043734</Text>
            <Text>Email : royalmosquitonets@gmail.com</Text>
            <Text>GSTIN/UIN: 33AMNPJ9237B1Z4</Text>
          </View>

          <View>
            <Text> </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableData}>
              <View>
                <Text>Month</Text>
                {props.allOutlets.map((item) => {
                  return <Text>{item.location ?? item.shopName}</Text>;
                })}
                <Text>Total</Text>
              </View>
              {Object.entries(JSON.parse(props.reportList)).map(
                ([key, value]) => {
                  return (
                    <>
                      <View>
                        <Text>
                          {value.month}-{value.year}
                        </Text>
                        {value.shops &&
                          Object.entries(value.shops).map(([key1, value1]) => {
                            return <Text>{value1.total.toFixed(2)}</Text>;
                          })}
                        <Text>
                          {Object.values(value.shops)
                            .reduce((sum, bill) => sum + bill.total, 0)
                            .toFixed(2)}
                        </Text>
                      </View>
                    </>
                  );
                }
              )}
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 10 },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    marginLeft: 10,
    marginRight: 10,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
    borderTopWidth: 1,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  label: {
    padding: 2,
    width: "20%",
    textAlign: "center",
  },
  WONo: {
    padding: 2,
    width: "25%",
    textAlign: "center",
  },
});
