import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Switch } from "antd";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getAllItemsPagination100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
const { RangePicker } = DatePicker;

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [addedBy, setAddedBy] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [totalPending, setTotalPending] = useState(0);
  const [status, setStatus] = useState("");
  const [showAll, setShowAll] = useState(false);
  const statusArray = ["Assign To RF", "Pending", "Installed"];
  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "12%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/install`)}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "10%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "15%",
      render: (name, record) => (
        <a
          style={{
            marginTop: -5,
            marginBottom: -5,
            color: "Black",
            fontWeight: record.status === "live" ? "bold" : "normal",
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
      width: "12%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: "10%",
      render: (status) => (status == "Assign To RF" ? "New" : status),
    },
    {
      title: "Install Date",
      dataIndex: "installDt",
      key: "installDt",
      ellipsis: true,
      width: "10%",
      render: (installDt) =>
        installDt ? moment(installDt, "YYYY-MM-DD").format("DD MMM") : "NA",
    },
    {
      title: "Assigned To",
      dataIndex: "assignToUser",
      key: "assignToUser",
      ellipsis: true,
      width: "18%",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "10%",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
    },
    {
      title: "Payment Pending",
      dataIndex: "paymentDetails",
      key: "paymentDetails",
      ellipsis: true,
      width: "15%",
      render: (paymentDetails) => JSON.parse(paymentDetails).paymentPending,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "7%",
      render: (id) => (
        <a onClick={() => navigate(`../SqftCompQuot/${id}/install`)}>View</a>
      ),
    },
  ];

  const ColumnsDashboard = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Open",
      dataIndex: "open",
      key: "open",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Closed",
      dataIndex: "closed",
      key: "closed",
      width: "10%",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          setShopId(user.attributes["custom:shopId"]);
          await getAllItemsByType(
            user.attributes["custom:shopId"],
            user.attributes["custom:outletShopId"],
            user.attributes.email
          );
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Installation")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId, outletShopId, addedBy) => {
    let temp = {};
    let allItems = [];
    let pending = 0;
    if (props.common.shopType == "master") {
      if (showAll) allItems = await getAllItemsPagination("Production", shopId);
      else allItems = await getAllItemsPagination100("Production", shopId);
    } else {
      if (showAll)
        allItems = await getAllItemsPagination("Order", outletShopId);
      else allItems = await getAllItemsPagination100("Order", outletShopId);
    }
    allItems = allItems.filter(
      (item) =>
        item.status == "Dispatched" ||
        item.status == "Delivered" ||
        item.status == "Installed" ||
        item.status == "Pending" ||
        item.status == "Assign To RF"
    );
    if (props.common.shopType == "outlet" && props.common.role == "Technician")
      allItems = allItems.filter((item) => item.assignToUser == addedBy);
    if (status != "")
      allItems = allItems.filter((item) => item.status == status);
    allItems.map((item) => {
      pending = pending + JSON.parse(item.paymentDetails).paymentPending;
    });

    if (dateRange[0] != "" && dateRange[1] != "") {
      let startDate = dateRange[0].format("YYYY-MM-DD");
      let endDate = dateRange[1].format("YYYY-MM-DD");

      allItems = allItems.filter(
        (item) =>
          moment(item.installDt, "YYYY-MM-DD").isSameOrAfter(startDate) &&
          moment(item.installDt, "YYYY-MM-DD").isSameOrBefore(endDate)
      );
    }
    allItems = allItems.sort(
      (a, b) =>
        moment(b.installDt, "YYYY-MM-DD") - moment(a.installDt, "YYYY-MM-DD")
    );

    allItems.map((item) => {
      let user = item.assignToUser ?? "Unassigned";
      if (temp[user] == undefined) {
        temp[user] = {};
        temp[user].user = user;
        temp[user].count = 0;
        temp[user].open = 0;
        temp[user].closed = 0;
      }
      temp[user].count = temp[user].count + 1;
      if (item.status == "Dispatched" || item.status == "Delivered")
        temp[user].open = temp[user].open + 1;
      if (item.status == "Installed") temp[user].closed = temp[user].closed + 1;
    });
    setAllItems(allItems);
    setTotalPending(pending);
    setDashboard(temp);
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      await getAllItemsByType(shopId, outletShopId, addedBy);
    };
    if (shopId != "" && outletShopId != "" && addedBy != "") getData();
  }, [dateRange, status, showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 15,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            name="search"
            variant="outlined"
            size="small"
            style={{ width: "30%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <TextField
            select
            margin="normal"
            label="Select Status"
            style={{
              width: "15%",
              backgroundColor: "#ffff",
            }}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            {statusArray.map((item) => (
              <MenuItem key={item} value={item}>
                {item == "Assign To RF" ? "New" : item}
              </MenuItem>
            ))}
          </TextField>
          <RangePicker
            style={{ width: "15%", marginLeft: 30 }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          <label style={{ fontSize: 16, fontWeight: "bold" }}>
            Pending Amount: {parseFloat(totalPending).toFixed(2)}
          </label>
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all Items"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 720 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <div style={{ margin: 10 }}></div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={ColumnsDashboard}
                  dataSource={Object.values(dashboard)}
                  scroll={{ y: 520 }}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
