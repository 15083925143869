import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Select, Button } from "antd";
import { getItemsdatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import InvoiceStmnt from "./genInvoiceStmnt";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const Columns = [
    {
      title: "Added Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
    },
    {
      title: "Payment type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      ellipsis: true,
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      ellipsis: true,
    },
    {
      title: "Installment Amount",
      dataIndex: "installmentAmount",
      key: "installmentAmount",
      ellipsis: true,
    },
    {
      title: "Payment Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Payment Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
      render: (time) => moment(time, "HH:mm:ss").format("hh:mm A"),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Statement")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allPP = await getItemsdatePagination(
      "PurchasePayment",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );

    let allSP = await getItemsdatePagination(
      "SalesPayment",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let allItems = allPP.concat(allSP);
    allItems = allItems.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });

    setAllItems(allItems);
    setSpinnerState(false);
  };

  useEffect(() => {
    const getInvoice = async () => {
      setAllItems([]);
      setSpinnerState(true);
      await getAllItemsByType(outletShopId);
    };
    if (outletShopId != "") getInvoice();
  }, [dateRange]);

  // const downloadPDF = async () => {
  //   let date = `${dateRange[0].format("DD/MM/YY")}-${dateRange[1].format(
  //     "DD/MM/YY"
  //   )}`;
  //   await pdf(<InvoiceStmnt allItems={allItems} dateRange={date} />)
  //     .toBlob()
  //     .then(async (blob) => {
  //       saveAs(
  //         blob,
  //         `Payment Report-${dateRange[0].format(
  //           "DD/MM/YY"
  //         )}-${dateRange[1].format("DD/MM/YY")}.pdf`
  //       );
  //     });
  // };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          {/* {allItems.length > 0 && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )} */}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allItems}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
