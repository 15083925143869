import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, DatePicker } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import { getAllItemsPagination } from "./functionCall";
import { CSVLink } from "react-csv";

function ListST(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [date, setDate] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [show, setShow] = useState(false);

  const headers = [
    { label: "Id", key: "randomId" },
    { label: "Supplier Id", key: "PO_ID" },
    { label: "Date", key: "date" },
    { label: "Vendor Name", key: "vendorName" },
    show ? { label: "Status", key: "paymentStatus" } : null,
    show ? { label: "Payment Due Date", key: "paymentDueDate" } : null,
    show ? { label: "Sub Total", key: "subTotal" } : null,
    show ? { label: "P & F", key: "extraCharges" } : null,
    show ? { label: "GST Amount", key: "GSTAmount" } : null,
    show ? { label: "IGST Amount", key: "IGSTAmount" } : null,
    show ? { label: "Payment Done", key: "paymentDone" } : null,
    show ? { label: "Payment Pending", key: "paymentPending" } : null,
    show ? { label: "Total", key: "totalAmount" } : null,
  ];

  const Columns = [
    {
      title: "PI No.",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Supplier ID",
      dataIndex: "PO_ID",
      key: "PO_ID",
      ellipsis: true,
    },
    {
      title: "Invoice Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Vendor",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    ...(show
      ? [
          {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            ellipsis: true,
            render: (paymentStatus) =>
              paymentStatus == "false" ? "Due" : "Done",
          },
        ]
      : []),
    ...(show
      ? [
          {
            title: "Pay Due",
            dataIndex: "paymentDueDate",
            key: "paymentDueDate",
            ellipsis: true,
            render: (paymentDueDate) =>
              moment(paymentDueDate).format("DD MMM YY"),
          },
        ]
      : []),
    ...(show
      ? [
          {
            title: "Sub-Total",
            dataIndex: "subTotal",
            key: "subTotal",
            ellipsis: true,
            render: (subTotal) => subTotal.toFixed(2),
          },
        ]
      : []),
    ...(show
      ? [
          {
            title: "P & F",
            dataIndex: "extraCharges",
            key: "extraCharges",
            ellipsis: true,
            render: (extraCharges) => extraCharges.toFixed(2),
          },
        ]
      : []),
    ,
    ...(show
      ? [
          {
            title: "GST Amount",
            dataIndex: "GSTAmount",
            key: "GSTAmount",
            ellipsis: true,
            render: (GSTAmount) => GSTAmount.toFixed(2),
          },
        ]
      : []),
    ,
    ...(show
      ? [
          {
            title: "IGSTAmount Amount",
            dataIndex: "IGSTAmount",
            key: "IGSTAmount",
            ellipsis: true,
            render: (IGSTAmount) => IGSTAmount.toFixed(2),
          },
        ]
      : []),
    ,
    ...(show
      ? [
          {
            title: "Payment Done",
            dataIndex: "paymentDone",
            key: "paymentDone",
            ellipsis: true,
            render: (paymentDone) => parseFloat(paymentDone).toFixed(2),
          },
        ]
      : []),
    ,
    ...(show
      ? [
          {
            title: "Payment Pending",
            dataIndex: "paymentPending",
            key: "paymentPending",
            ellipsis: true,
            render: (paymentPending) => parseFloat(paymentPending).toFixed(2),
          },
        ]
      : []),
    ...(show
      ? [
          {
            title: "Total",
            dataIndex: "totalAmount",
            key: "totalAmount",
            ellipsis: true,
            render: (totalAmount) => totalAmount.toFixed(2),
          },
        ]
      : []),
    ,
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => <a onClick={() => navigate(`../AddPI/${id}`)}>View</a>,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          if (
            props.common.role != "Store" &&
            props.common.role != "ProdManager"
          )
            setShow(true);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("PurchaseInvoice")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getAllItemsPagination("PurchaseInvoice", outletShopId);
    console.log(date);
    if (date != "" && date != null)
      allItems = allItems.filter(
        (item) => item.paymentDueDate == date.format("YYYY-MM-DD")
      );
    allItems.sort(
      (a, b) => moment(b.date, "YYYY-MM-DD") - moment(a.date, "YYYY-MM-DD")
    );
    setAllItems(allItems);
  };

  useEffect(() => {
    if (outletShopId != "") getAllItemsByType(outletShopId);
  }, [date]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "50%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label>Due Date: </label>
            <DatePicker
              placeholder="Due Date"
              onChange={(date) => setDate(date)}
              value={date}
              style={{ width: "80%", height: 40, marginTop: 15 }}
            />
          </div>
          {allItems.length > 0 && (
            <Button type="primary">
              <CSVLink data={allItems} headers={headers} filename={"PI.csv"}>
                Export to CSV
              </CSVLink>
            </Button>
          )}
          {props.common.role != "Store" &&
            props.common.role != "ProdManager" && (
              <Button type="primary">
                <Link to="../ListVendor/vendor">List Vendors</Link>
              </Button>
            )}
          <Button type="primary">
            <Link to="../AddPI/addNewPI">New Purchase Invoice</Link>
          </Button>
        </div>
        <p style={{ fontWeight: "bold" }}>
          {term == ""
            ? allItems.length
            : allItems.filter(
                (item) =>
                  item.randomId
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1 ||
                  item.PO_ID.toString()
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1 ||
                  item.vendorName
                    .toString()
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              ).length}{" "}
          Purchase invoice(s) of value{" "}
          {term == ""
            ? allItems
                .reduce((sum, bill) => sum + bill.totalAmount, 0)
                .toFixed(2)
            : allItems
                .filter(
                  (item) =>
                    item.randomId
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1 ||
                    item.PO_ID.toString()
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1 ||
                    item.vendorName
                      .toString()
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1
                )
                .reduce((sum, bill) => sum + bill.totalAmount, 0)
                .toFixed(2)}
        </p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.PO_ID.toString()
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.vendorName
                              .toString()
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListST);
