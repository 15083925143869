import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Select, Button } from "antd";
import {
  getItemByTypeDateStatusPagination,
  getItemsByTypeFunc,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";
import moment from "moment";

function ReportOutletInvoice(props) {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [from, setFrom] = useState("");
  const [category, setCategory] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const { Option } = Select;

  const Columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
    },
    {
      title: "Invoice Count",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
    },
    {
      title: "Due Amount",
      dataIndex: "paymentPending",
      key: "paymentPending",
      ellipsis: true,
      render: (paymentPending) => parseFloat(paymentPending).toFixed(2),
    },
  ];

  const headers = [
    { label: "Location", key: "location" },
    { label: "Count", key: "total" },
    { label: "Payment Pending", key: "paymentPending" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportOutletInvc")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (from, shopId, outletShopId) => {
    let allItems = await getItemsByTypeFunc(from, shopId);
    if (category != "")
      allItems = allItems.filter((item) => item.category == category);
    let temp = {};

    allItems.map((item) => {
      item.location = from == "Outlet" ? item.location : item.shopName;
      let id = item.id;
      temp[id] = {};
      temp[id].id = id;
      temp[id].location = item.location;
      temp[id].category = item.category;
      temp[id].paymentPending = 0;
      temp[id].total = 0;
    });

    console.log(JSON.stringify(temp));

    for (const value of Object.values(temp)) {
      let allItems = await getItemByTypeDateStatusPagination(
        from == "Vendor" ? "PurchaseInvoice" : "SalesInvoice",
        outletShopId,
        value.id,
        moment(new Date()).format("YYYY-MM-DD"),
        "false"
      );
      console.log(allItems.length);
      if (allItems.length > 0)
        allItems.map((item) => {
          value.paymentPending = value.paymentPending + item.paymentPending;
          value.total = value.total + 1;
        });
    }

    setAllInvoice(Object.values(temp));
    setSpinnerState(false);
  };

  useEffect(() => {
    const getInvoice = async () => {
      setAllInvoice([]);
      setSpinnerState(true);
      await getAllItemsByType(from, shopId, outletShopId);
    };
    if (shopId != "" && from != "" && outletShopId != "") getInvoice();
  }, [from, category]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: "15%" }}
            value={from}
            onChange={(value) => {
              setFrom(value);
              setCategory("");
            }}
          >
            <Option value="">Select Outlet/Vendor</Option>
            <Option value="Outlet">Outlet</Option>
            <Option value="Vendor">Vendor</Option>
            <Option value="Dealer">Dealer</Option>
          </Select>
          <Select
            style={{
              width: "20%",
              backgroundColor: "white",
              height: 40,
              marginTop: 15,
            }}
            value={category}
            onChange={(value) => setCategory(value)}
          >
            <Option value="">Select Category</Option>
            {from == "Outlet"
              ? props.common.outletCategoryArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))
              : from == "Vendor"
              ? props.common.purchaseCatArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))
              : props.common.dealerCatArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
          </Select>
          {allInvoice.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={allInvoice}
                headers={headers.filter((header) => header !== null)}
                filename={from.split(/(?=[A-Z])/).join(" ") + ".csv"}
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        <strong>Total</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <strong>
                          {allInvoice.reduce(
                            (sum, bill) => sum + bill.total,
                            0
                          )}
                        </strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <strong>
                          {allInvoice
                            .reduce((sum, bill) => sum + bill.paymentPending, 0)
                            .toFixed(2)}
                        </strong>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>
            </Card>
          </Col>
          <p></p>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportOutletInvoice);
