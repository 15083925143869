import { useNavigate, Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";
import { api } from "../index";
import axios from "axios";
import * as mutations from "../graphql/mutations";

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [shopId, setShopId] = useState("");
  const [term, setTerm] = useState("");
  let { test, from } = useParams();
  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
      render: (location) => location,
    },
    {
      title: "Shop Type",
      dataIndex: "shopType",
      key: "shopType",
      ellipsis: true,
      render: (shopType) =>
        shopType.charAt(0).toUpperCase() + shopType.slice(1),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Disbale",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id) => (
              <Button type="primary" danger onClick={() => disableUser(id)}>
                Disable
              </Button>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ManageUser")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc(
      test === "homepage" ? props.common.shopName + " User" : test + " User",
      test === "homepage" ? shopId : from
    );
    setAllItems(allItems);
  };

  const disableUser = async (id) => {
    await axios
      .get(api + "disableUser", {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log("User disabled");
        editItem(id);
      })
      .catch((error) => {
        console.error("Error disabling user:", error);
      });
  };

  const editItem = async (id) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: id,
          status: "disabled",
        },
      });
      alert("User Disabled");
      getItemsByType(shopId);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by email"
            name="search by email"
            variant="outlined"
            size="small"
            style={{ width: "60%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />

          <Button type="primary">
            <Link to="../SignUp">
              <PlusOutlined /> Add New User
            </Link>
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.email
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.location
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 420 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
